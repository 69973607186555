
import { defineComponent, ref, getCurrentInstance } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import PermissionForm from './PermissionForm.vue';
import useListData from './usePermissionListdata';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading, errorMessage } from '@/utils/publicMethods';
import router from '@/router';
import { httpPost } from '@/api';
export default defineComponent({
  name: 'systemPermissionList',
  components: { CrmList, PermissionForm },
  props: {},
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, formType, setFormType } = useVisible();
    const rowData = ref({});
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const editActionFormRef = ref();
    const methodType = ref('');
    const dialogName = ref('');
    const menuTreeData = ref([]);

    // 打开新增弹窗
    const openDrawer = (formType: number, row?): void => {
      setFormType(formType);
      showVisible();
      if (formType === 1) {
        (rowData.value as any).status = 'ENABLE';
        (rowData.value as any).parentActionName = '';
        dialogName.value = '添加权限';
        methodType.value = 'ADD';
      }
      if (formType === 2) {
        dialogName.value = '编辑权限';
        methodType.value = 'UPDATE';
        rowData.value = row;
        if ((rowData.value as any).menuId) {
          const menuIdList: any[] = [];
          menuIdList.push((rowData.value as any).menuId);
          (rowData.value as any).menuIdList = menuIdList;
        }
      }
    };
    const setRowData = (row) => {
      rowData.value = row;
    };

    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable(status);
      }
    };
    const saveActionFn = () => {
      editActionFormRef.value.submitForm(() => {
        closeVisible(true);
      });
    };
    const getLoadPage = (url) => {
      router.push(url);
    };

    const initMenuTree = async () => {
      const params = {};
      ajaxLoading.lock();
      const res = await httpPost('/uac/menu/getTree', params);
      if (res.code === 200) {
        const data = res.result;
        if (!data) {
          return;
        }
        menuTreeData.value = data[0].subMenu;
      } else {
        errorMessage(res.message);
      }
    };
    initMenuTree();
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showVisible,
      closeVisible,
      setFormType,
      getLoadPage,
      rowData,
      setRowData,
      openDrawer,
      methodType,
      menuTreeData,
      dialogName,
      initMenuTree,
      editActionFormRef,
      saveActionFn,
    };
  },
});
