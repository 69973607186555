import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import { erpConfirm, errorMessage } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'cascader',
      prop: 'menuIdList',
      value: [],
      options: [],
      placeholder: '请选择权限',
      menuProps: {
        value: 'id',
        label: 'menuName',
        children: 'subMenu',
        checkStrictly: true,
      },
    },
    {
      type: 'input',
      prop: 'actionCode',
      value: '',
      placeholder: '请输入权限编码',
    },
    {
      type: 'input',
      prop: 'actionName',
      value: '',
      placeholder: '请输入权限名称',
    },
    {
      type: 'input',
      prop: 'url',
      value: '',
      placeholder: '请输入权限URL',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '权限名称',
      prop: 'actionName',
      minWidth: 140,
    },
    {
      label: '权限编码',
      prop: 'actionCode',
      minWidth: 160,
    },
    {
      label: 'URL地址',
      prop: 'url',
      minWidth: 160,
    },
    {
      label: '菜单名称',
      prop: 'menuName',
      minWidth: 120,
    },
    {
      label: '修改时间',
      prop: 'updateTime',
      minWidth: 120,
    },
    {
      label: '操作人',
      prop: 'lastOperator',
      minWidth: 120,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups" v-if="row.id !== 1">
            <el-button type="primary" plain @click="edit(row)">修改</el-button>
            <el-button type="warning" plain @click="deleteRow(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (action) => {
            Instance.setupState.openDrawer(2, action);
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            const confirmMsg =
              '确定删除' +
              row.actionName +
              '吗? 删除后将不能使用，确定继续删除吗?';
            erpConfirm(confirmMsg)
              .then(async () => {
                const res = await httpPost(
                  `/uac/action/deleteActionById/${row.id}`,
                  {}
                );
                if (res.code === 200) {
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      prop: '',
      width: 200,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
