<template>
  <el-form :model="formData" :rules="rules" ref="actionForm" label-width="80px">
    <el-form-item label="菜单名称" v-if="type === 'ADD'">
      <el-cascader
        size="small"
        placeholder="请选择企业地址"
        :options="menuTreeData"
        filterable
        clearabled
        change-on-select
        v-model="formData.menuIdList"
        :props="menuProps"
        :disabled="type === 'VIEW' || type === 'UPDATE'"
        class="menu-cascader"
      >
      </el-cascader>
    </el-form-item>
    <el-form-item
      label="菜单名称"
      prop="menuName"
      label-width="80px"
      v-if="type !== 'ADD'"
    >
      <el-input
        size="small"
        clearable
        v-model="formData.menuName"
        :disabled="true"
        class="pc-input-auto"
      ></el-input>
    </el-form-item>
    <el-form-item label="权限编码" prop="actionCode" label-width="80px">
      <el-input
        size="small"
        clearable
        v-model="formData.actionCode"
        :disabled="type === 'UPDATE'"
        class="pc-input-auto"
      ></el-input>
    </el-form-item>
    <el-form-item label="权限名称" prop="actionName" label-width="80px">
      <el-input
        size="small"
        clearable
        v-model="formData.actionName"
        class="pc-input-auto"
      ></el-input>
    </el-form-item>
    <el-form-item label="权限地址" prop="url">
      <el-input
        v-model="formData.url"
        size="small"
        clearable
        :readonly="type === 'VIEW'"
        class="pc-input-auto"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="权限说明" prop="remark" label-width="80px">
      <el-input
        size="small"
        clearable
        v-model="formData.remark"
        class="pc-input-auto"
      ></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { httpPost } from '@/api';
export const checkRoleName = (rule, value, callback) => {
  if (value !== '') {
    const reg = /^[\u4e00-\u9faf]+$/;
    const val = reg.test(value);
    if (val !== true) {
      callback(new Error('请输入汉字'));
    } else {
      callback();
    }
  } else {
    callback(new Error('内容不能为空'));
  }
};
export default {
  props: {
    rowData: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
    menuTreeData: {
      type: Array,
    },
  },
  created() {
    const propsKeys = Object.keys(this.rowData);
    propsKeys.forEach((prop) => {
      this.formData[prop] = this.rowData[prop];
    });
  },
  data() {
    const checkUrl = async (rule, value, callback) => {
      if (value !== '' && value !== undefined && value !== null) {
        const reg = /^[/]/ || /^[a-zA-Z0-9_]+$/;
        const val = reg.test(value);
        if (val !== true) {
          callback(new Error('请输入正确的格式'));
        } else {
          const param = {};
          param.url = value;
          param.actionId = this.formData.id;
          console.log('dddd');
          const res = await httpPost('/uac/action/checkUrl', param);
          if (res.result === false) {
            callback(new Error('权限地址已存在'));
          } else {
            callback();
          }
        }
      } else {
        callback(new Error('内容不能为空'));
      }
    };
    const checkActionCode = async (rule, actionCode, callback) => {
      if (
        actionCode !== '' &&
        actionCode !== undefined &&
        actionCode !== null
      ) {
        const reg = /^[A-Za-z0-9:]+$/;
        const val = reg.test(actionCode);
        if (val !== true) {
          callback(new Error('请输入正确的格式'));
        } else {
          const param = {};
          param.actionCode = actionCode;
          param.actionId = this.formData.id;
          const res = await httpPost('/uac/action/checkActionCode', param);
          if (res.result) {
            callback();
          } else {
            callback(new Error('权限编码已存在'));
          }
        }
      } else {
        callback(new Error('内容不能为空'));
      }
    };
    return {
      formData: {
        actionCode: '',
        actionName: '',
        url: '',
        remark: '',
        id: '',
      },
      rules: {
        actionCode: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { min: 0, max: 50, message: '长度不能大于50', trigger: 'blur' },
          { validator: checkActionCode, trigger: 'blur' },
        ],
        actionName: [
          { required: true, message: '长度不能大于50', trigger: 'blur' },
          { min: 0, max: 50, message: '长度不能大于50', trigger: 'blur' },
          { validator: checkRoleName, trigger: 'blur' },
        ],
        url: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { min: 0, max: 100, message: '长度不能大于100', trigger: 'blur' },
          { validator: checkUrl, trigger: 'blur' },
        ],
        remark: [
          { min: 0, max: 150, message: '长度不能大于150', trigger: 'blur' },
        ],
      },
      menuProps: {
        value: 'id',
        label: 'menuName',
        children: 'subMenu',
        checkStrictly: true,
      },
    };
  },
  methods: {
    submitForm(fn) {
      this.$refs.actionForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.saveActionFn(fn);
      });
    },
    saveActionFn(fn) {
      this.ajax({
        url: '/uac/action/save',
        data: this.formData || '',
        loading: true,
        success: (res) => {
          fn && fn(res);
        },
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.menu-cascader {
  width: 100%;
  .el-cascader__label {
    line-height: 36px;
  }
}
</style>
